/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 665px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 52.409638554216876%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABiZnMzUEYv//EABkQAAMAAwAAAAAAAAAAAAAAAAABAhASIf/aAAgBAQABBQKJTNRx1Dx//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Bp//EABgQAAIDAAAAAAAAAAAAAAAAAAAyASAx/9oACAEBAAY/AmGNin//xAAbEAACAgMBAAAAAAAAAAAAAAAAASGBETFBUf/aAAgBAQABPyHQUHn6O5VjOZHGz//aAAwDAQACAAMAAAAQHM//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPxCH/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxCaIz//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFh0f/aAAgBAQABPxADHZ6si25ZpWDkg8nqM7zEKBdfIhVLqf/Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"NY Jets origin\"\n        title=\"NY Jets origin\"\n        src=\"/static/47ce6c11f436dc3cc5fcedd34cbd49d5/0d8e0/ny-jets.jpg\"\n        srcset=\"/static/47ce6c11f436dc3cc5fcedd34cbd49d5/4d5fb/ny-jets.jpg 166w,\n/static/47ce6c11f436dc3cc5fcedd34cbd49d5/558f0/ny-jets.jpg 333w,\n/static/47ce6c11f436dc3cc5fcedd34cbd49d5/0d8e0/ny-jets.jpg 665w,\n/static/47ce6c11f436dc3cc5fcedd34cbd49d5/90334/ny-jets.jpg 998w,\n/static/47ce6c11f436dc3cc5fcedd34cbd49d5/6c738/ny-jets.jpg 1200w\"\n        sizes=\"(max-width: 665px) 100vw, 665px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "It wasn’t until 1959 that Harry Wismer was granted a franchise for a professional football team in New York. \nOne the driving forces was to compete with the NY Giants so to that end Harry named the fledgling team the Titans of New York (after all, he claimed that Titans were bigger and stronger than Giants). "), "\n", React.createElement(_components.p, null, "As with many nascent professional teams the Titans struggled financially in the beginning and Harry was forced to sell the team in 1963.  Sonny Werblin was one of five men to buy the team and renamed the team the “Jets”. "), "\n", React.createElement(_components.p, null, "He chose the name because it rhymed with “Mets” with whom they shared a stadium. "), "\n", React.createElement(_components.p, null, "Additionally, the colors green and white were chosen because Werblin was born on St. Patrick’s Day. "), "\n", React.createElement(_components.p, null, "The Jets proved their worth later in Super Bowl III when they upset the favored Colts. "), "\n", React.createElement(_components.p, null, "The Jets would later merge with the NFL in 1970."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
